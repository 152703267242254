import en from "/locales/en-US.yml";
import al from "/locales/al-AL.yml";

export default defineI18nConfig(() => {
  return {
    legacy: true,
    locale: 'en',
    messages: {
      en,
      al,
    }
  }
})
